import * as React from "react"
import Layout from '../components/Layout'
import {css} from '@emotion/react'
import GalleryComponent from '../components/galleryParkHeights'

const GalleryPage = () => {
  return(
     <div>
      <Layout title="Adarsh Park Heights | Adarsh Park Heights Gallery">
      <div style={{marginTop:"12vh", textAlign:"center"}}>
          <GalleryComponent/>
        </div>
      </Layout>
     </div>
  )
}

export default GalleryPage

